@import url("https://fonts.googleapis.com/css?family=Work+Sans:400");
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(1vw); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes fadeDown {
  0% {
    opacity: 0;
    transform: translateY(-2vw); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes float {
  0% {
    transform: translateY(10%); }
  50% {
    transform: translateY(-10%); }
  100% {
    transform: translateY(10%); } }

@keyframes stretchIt {
  0% {
    width: 1.5rem;
    height: 1.5rem; }
  75% {
    width: 3.5rem;
    height: 3.5rem; }
  100% {
    width: 2.5rem;
    height: 2.5rem; } }

@keyframes shakePrev {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(-10px); }
  100% {
    transform: translateX(0); } }

@keyframes shakeNext {
  0% {
    transform: translateX(0); }
  50% {
    transform: translateX(10px); }
  100% {
    transform: translateX(0); } }

@keyframes fadeOutAndIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

html {
  box-sizing: border-box;
  font-size: 62.5%; }

body {
  background-color: #1a1a1a; }

html.open {
  overflow-y: hidden;
  -webkit-overflow-scrolling: none; }

*,
*::after,
*::before {
  box-sizing: inherit;
  margin: 0;
  padding: 0; }

main {
  display: block; }

p,
a,
h1,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #f8f8f8; }

::selection {
  color: #1a1a1a;
  background-color: #f8f8f8; }

a {
  background-color: transparent;
  text-decoration: none; }

ul {
  list-style: none; }

button * {
  pointer-events: none; }

img,
embed,
iframe,
object,
video {
  display: block;
  max-width: 100%;
  border-style: none; }

input,
textarea,
select,
button {
  color: inherit;
  font: inherit;
  letter-spacing: inherit; }

body.open {
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch; }

@font-face {
  src: url(../fonts/Agrandir/Agrandir-regular.otf);
  font-family: "Agrandir";
  font-weight: 400; }

body {
  font-family: "Agrandir";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

p,
a,
h1,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Agrandir";
  font-weight: 400;
  transition: color 0.2s; }

a button {
  font-size: 1.4rem; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Agrandir";
  font-weight: 400; }

h1 {
  font-size: 7.2rem;
  line-height: 1.2; }
  @media (max-width: 75em) {
    h1 {
      font-size: calc(7.2rem - 8px); } }
  @media (max-width: 56.25em) {
    h1 {
      font-size: calc(7.2rem - 16px); } }

h2 {
  font-size: 5.6rem;
  line-height: 1.2; }
  @media (max-width: 75em) {
    h2 {
      font-size: calc(5.6rem - 8px); } }
  @media (max-width: 56.25em) {
    h2 {
      font-size: calc(5.6rem - 16px); } }

h3 {
  font-size: 4.3rem;
  line-height: 1; }
  @media (max-width: 75em) {
    h3 {
      font-size: calc(4.3rem - 8px); } }
  @media (max-width: 56.25em) {
    h3 {
      font-size: calc(4.3rem - 16px); } }

h4 {
  font-size: 3.3rem;
  line-height: 1.3; }
  @media (max-width: 75em) {
    h4 {
      font-size: calc(3.3rem - 8px); } }
  @media (max-width: 56.25em) {
    h4 {
      font-size: calc(3.3rem - 12px); } }

h5 {
  font-size: 2.4rem;
  line-height: 1.4; }
  @media (max-width: 75em) {
    h5 {
      font-size: calc(2.4rem - 6px); } }

h6 {
  font-size: 1.6rem;
  line-height: 1.4; }
  @media (max-width: 75em) {
    h6 {
      font-size: 1.5rem; } }
  @media (max-width: 56.25em) {
    h6 {
      font-size: 1.4rem; } }

button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem 0.7rem 2rem;
  color: #1a1a1a;
  background-color: #f8f8f8;
  border: 1px solid #1a1a1a;
  letter-spacing: 0.091em;
  line-height: 1em;
  text-align: center;
  white-space: nowrap;
  outline: none;
  transition: all 0.3s ease;
  cursor: pointer; }
  button:hover {
    border: 1px solid #1a1a1a;
    color: #f8f8f8;
    background-color: #1a1a1a; }

body {
  display: grid;
  grid-template-columns: [page-start] 1fr [content] repeat(12, [col-start] 1fr [col-end]) [content-end] 1fr [page-end]; }

.form {
  min-width: 35rem; }
  @media (max-width: 56.25em) {
    .form {
      min-width: 100%; } }
  .form .heading {
    margin-bottom: 2.4rem; }
  .form__input {
    display: block;
    width: 100%;
    padding: 1.2rem 2.2rem 0.5rem 1.2rem;
    border: none;
    border: 1px solid #f8f8f8;
    background-color: transparent;
    font-family: inherit;
    font-size: 1.2rem;
    color: #f8f8f8; }
    @media (max-width: 56.25em) {
      .form__input {
        width: 100%; } }
    .form__input--textarea {
      resize: none;
      width: 100%;
      min-height: 15rem;
      border: 1px solid #f8f8f8; }
      .form__input--textarea ~ .form__label {
        transform: translate(-7.4rem, -14.2rem); }
    .form__input--submit {
      display: none; }
  .form__input::-webkit-input-placeholder {
    color: white; }
  .form__input::-moz-placeholder {
    /* Firefox 19+ */
    color: white; }
  .form__input::-ms-input-placeholder {
    /* IE 10+ */
    color: white; }
  .form__input::-moz-placeholder {
    /* Firefox 18- */
    color: white; }
  .form__input:focus {
    outline: none;
    border-bottom: 1px solid #deff4a; }
  .form__input:focus:invalid {
    border-bottom: 1px solid orangered; }
  .form__label {
    backface-visibility: hidden;
    display: block;
    transform: translate(-5.5rem, -3rem);
    margin-top: 0.5rem;
    margin-left: 1.2rem;
    opacity: 0;
    color: #f8f8f8;
    font-size: 1.2rem;
    transition: all 0.3s ease; }
  .form__input:placeholder-shown + .form__label {
    opacity: 0;
    visibility: hidden; }

.image-set {
  position: relative;
  width: 100%;
  margin: 0 auto;
  transition: all .3s;
  animation: fadeIn 0s; }
  .image-set:hover {
    transform: translateY(-10px) scale(1.01);
    box-shadow: 0 1.2rem 2rem rgba(20, 20, 20, 0.05); }
    @media (max-width: 37.5em) {
      .image-set:hover {
        transform: translateY(0px) scale(1); } }
  .image-set .overlay {
    opacity: 0;
    transition: all .3s ease;
    position: absolute;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.28);
    width: 100%;
    height: 100%;
    border-radius: .5rem; }
  .image-set h5 {
    font-size: 1.6rem;
    position: absolute;
    z-index: 3;
    top: 3rem;
    left: 2rem;
    opacity: 0;
    color: #f8f8f8;
    transition: opacity 0.5s, top 0.5s; }
  .image-set img {
    position: relative;
    z-index: 1;
    width: 100%;
    border-radius: .5rem; }

a:hover > .image-set .overlay,
a:focus > .image-set .overlay {
  opacity: 1; }

a:focus > .image-set {
  transform: translateY(-10px) scale(1.01);
  box-shadow: 0 1.2rem 2rem rgba(20, 20, 20, 0.05); }
  @media (max-width: 37.5em) {
    a:focus > .image-set {
      transform: translateY(0px) scale(1); } }

a:hover > .image-set h5,
a:focus > .image-set h5 {
  top: 2rem;
  opacity: 1; }

.light-switch {
  position: fixed;
  z-index: 3;
  bottom: 3%;
  right: 3%;
  transform: translate(-50%, -50%);
  width: 2.5rem;
  height: 2.5rem;
  transition: all 0.5s; }
  @media (max-width: 56.25em) {
    .light-switch {
      display: none; } }

.light-switch-toggle {
  position: absolute;
  z-index: 1;
  top: 30%;
  left: 100%;
  transform: translate(-50%, -50%);
  -webkit-appearance: none;
  opacity: 0;
  visibility: hidden;
  width: 4.5rem;
  height: 4.5rem;
  border: 2px solid #f8f8f8;
  border-radius: 50%;
  background-color: #1a1a1a;
  transition: all 0.5s; }

.light-switch-toggle-ui {
  position: absolute;
  top: 50%;
  left: 25%;
  transform-origin: center;
  transform: translate(-50%, -50%);
  display: block;
  width: 4.5rem;
  height: 2rem;
  border: 2px solid #f8f8f8;
  border-radius: 2rem;
  background-color: #f8f8f8;
  -webkit-box-shadow: 6px 8px 16px -7px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 6px 8px 16px -7px rgba(0, 0, 0, 0.6);
  box-shadow: 6px 8px 16px -7px rgba(0, 0, 0, 0.6);
  transition: all 0.5s;
  cursor: pointer; }
  .light-switch-toggle-ui span {
    white-space: nowrap;
    position: absolute;
    transition: all 0.2s;
    font-size: xx-small;
    top: 0;
    left: 0;
    transform: translateX(-10%);
    opacity: 0;
    color: #f8f8f8; }
  .light-switch-toggle-ui:hover span {
    opacity: 1;
    top: -25px; }

.light-switch-toggle-ui--right {
  position: absolute;
  top: 50%;
  left: 80%;
  transform-origin: center;
  transform: translate(-50%, -50%);
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #1a1a1a;
  transition: all 0.1s ease-out;
  cursor: pointer; }

.light-switch-toggle-ui.active {
  background-color: #1a1a1a;
  border: 2px solid #f8f8f8; }

.circle {
  z-index: 10;
  width: 0rem;
  height: 0rem;
  display: block;
  border-radius: 100%;
  background-color: white;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
  mix-blend-mode: difference;
  pointer-events: none; }

.prevNext {
  display: flex;
  grid-column: col-start 11/col-end 11;
  align-items: baseline;
  justify-content: space-between;
  margin: 3.2rem 0; }
  @media (max-width: 37.5em) {
    .prevNext {
      grid-column: col-start 1/col-end 12;
      margin: 1rem 0 3.5rem 0; } }
  .prevNext a {
    position: relative; }
    .prevNext a:hover span {
      opacity: 1; }
    .prevNext a span {
      font-size: 1.6rem;
      transition: all .4s;
      padding-bottom: .2rem;
      border-bottom: 1px solid transparent; }
      .prevNext a span:hover {
        border-bottom: 1px solid #1a1a1a; }
  .prevNext a:not(:first-child) {
    margin-left: 2rem; }
  .prevNext [class^='arrow'] path {
    fill: #1a1a1a; }
  .prevNext .arrow__left {
    transform: rotate(-90deg); }
  .prevNext .arrow__right {
    transform: rotate(90deg); }

.header {
  position: relative;
  display: flex;
  grid-column: col-start 1/col-end 12;
  align-items: baseline;
  justify-content: space-between;
  max-height: 8rem;
  margin-top: 3.2rem;
  white-space: nowrap; }
  @media (max-width: 37.5em) {
    .header {
      justify-content: space-between;
      align-items: center;
      max-height: 6rem;
      margin-top: 3rem; } }

.navigation {
  display: flex;
  min-width: 24rem;
  justify-content: flex-end;
  align-items: baseline; }
  @media (max-width: 37.5em) {
    .navigation {
      min-width: 0; } }
  .navigation__checkbox {
    display: none; }
  .navigation:hover .navigation__link {
    color: #404040; }
  .navigation__button {
    display: none;
    z-index: 10;
    position: relative;
    height: 4rem;
    width: 4rem;
    cursor: pointer; }
    @media (max-width: 56.25em) {
      .navigation__button {
        display: block; } }
    .navigation__button:hover .navigation__icon::before {
      top: -.9rem; }
    .navigation__button:hover .navigation__icon::after {
      top: .9rem; }
  .navigation__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    display: none;
    width: 3rem;
    height: .2rem;
    background-color: #f8f8f8;
    transform: translate(-50%, -50%);
    transition: all .3s ease-in-out; }
    @media (max-width: 56.25em) {
      .navigation__icon {
        display: block; } }
    @media (max-width: 37.5em) {
      .navigation__icon {
        width: 2.2rem; } }
    .navigation__icon::before {
      content: "";
      position: absolute;
      top: -.7rem;
      display: block;
      width: 3rem;
      height: .2rem;
      background-color: #f8f8f8;
      transition: all .3s ease-in-out; }
      @media (max-width: 37.5em) {
        .navigation__icon::before {
          width: 2.2rem; } }
    .navigation__icon::after {
      content: "";
      position: absolute;
      top: .7rem;
      display: block;
      height: .2rem;
      width: 3rem;
      background-color: #f8f8f8;
      transition: all .3s ease-in-out; }
      @media (max-width: 37.5em) {
        .navigation__icon::after {
          width: 2.2rem; } }
  .navigation__checkbox:checked ~ .navigation__button > .navigation__icon::before {
    top: 0rem;
    transform-origin: center;
    transform: rotate(225deg); }
  .navigation__checkbox:checked ~ .navigation__button > .navigation__icon::after {
    top: 0rem;
    transform-origin: center;
    transform: rotate(-45deg); }
  .navigation__checkbox:checked ~ .navigation__button > .navigation__icon {
    background-color: transparent; }
  .navigation__checkbox:checked ~ .menu-full {
    opacity: 1;
    visibility: visible; }
  .navigation__list {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    @media (max-width: 56.25em) {
      .navigation__list {
        display: none; } }
    .navigation__list .navigation__item {
      align-items: flex-start; }
    .navigation__list .navigation__link {
      font-size: 1.4rem;
      padding-bottom: .3rem;
      border-bottom: 1px solid transparent; }
      .navigation__list .navigation__link:hover {
        color: #f8f8f8; }
      .navigation__list .navigation__link--checked {
        border-bottom: 1px solid #1a1a1a; }
      @media (max-width: 56.25em) {
        .navigation__list .navigation__link {
          font-size: 90%; } }

.menu {
  grid-column: col-start 8 / col-end 10;
  margin-top: 10rem; }
  @media (max-width: 37.5em) {
    .menu {
      margin-top: 2rem;
      grid-column: col-start 1 / col-end 1; } }
  .menu__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 70vh;
    white-space: nowrap;
    transition: color 1.2s; }
    .menu__list:hover .menu__link {
      color: #595959; }
  .menu__item {
    align-items: flex-start;
    transition: color .2s; }
    .menu__item:hover ~ .image-list > .image-set {
      opacity: 1; }
    .menu__item:hover .menu__link {
      color: #f8f8f8; }
  .menu__link {
    display: block;
    opacity: 0;
    transform: translateY(10vh);
    font-size: 2.4rem;
    transition: all .5s; }
    @media (max-width: 37.5em) {
      .menu__link {
        font-size: 2.8rem; } }
    .menu__link:hover {
      color: #1a1a1a; }
  .menu-full {
    z-index: 2;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    background-color: #1a1a1a;
    opacity: 0;
    visibility: hidden;
    transition: all .5s; }
    .menu-full__list {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      top: 40%;
      white-space: nowrap; }
      .menu-full__list:hover .menu__link {
        color: #595959; }
      .menu-full__list .menu__item {
        align-items: flex-start;
        transition: color .2s; }
        .menu-full__list .menu__item .menu__link {
          font-size: 6rem; }
          @media (max-width: 37.5em) {
            .menu-full__list .menu__item .menu__link {
              font-size: 4rem; } }
        .menu-full__list .menu__item:hover .menu__link {
          color: #f8f8f8; }

footer {
  display: flex;
  grid-column: col-start 2/ col-end 11;
  justify-content: flex-start;
  align-items: baseline;
  margin-bottom: 8rem;
  border: 1px solid red; }
  @media (max-width: 75em) {
    footer {
      grid-column: col-start 1 / col-end 12; } }
  @media (max-width: 37.5em) {
    footer {
      grid-column: col-start 1 / col-end 12;
      justify-content: flex-end; } }
  footer svg {
    height: 30%;
    cursor: pointer; }
    footer svg path {
      fill: #f8f8f8; }
  footer span {
    font-size: 1.6rem;
    cursor: pointer; }

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
  grid-column: col-start 3 / col-end 10;
  grid-gap: 3rem 3rem;
  min-height: 10vw;
  margin: 2rem 0 12rem 0; }
  @media (min-width: 112.5em) {
    .gallery {
      grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
      grid-column: col-start 4 / col-end 9; } }
  @media (max-width: 75em) {
    .gallery {
      grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr)); } }
  @media (max-width: 56.25em) {
    .gallery {
      grid-column: col-start 1 / col-end 12;
      margin: 6rem 0; } }
  @media (max-width: 37.5em) {
    .gallery {
      margin: 2rem 0; } }
  .gallery-set {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    grid-column: col-start 2 / col-end 11;
    grid-gap: 5rem 5rem;
    min-height: 20vw;
    margin: 8rem 0 6rem 0; }
    @media (max-width: 75em) {
      .gallery-set {
        grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
        grid-column: col-start 2 / col-end 11; } }
    @media (max-width: 37.5em) {
      .gallery-set {
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
        grid-column: col-start 1 / col-end 12;
        margin: 3rem 0; } }
    .gallery-set .image-set {
      position: relative;
      width: 100%;
      cursor: auto;
      cursor: pointer; }
      @media (max-width: 75em) {
        .gallery-set .image-set {
          max-width: 100%; } }
      @media (max-width: 37.5em) {
        .gallery-set .image-set {
          max-width: 100%; } }
    @media (max-width: 56.25em) {
      .gallery-set .remove {
        display: none; } }

[data-theme="dark-mode"] body {
  background-color: #f8f8f8; }

[data-theme="dark-mode"] p,
[data-theme="dark-mode"] a,
[data-theme="dark-mode"] h1,
[data-theme="dark-mode"] h2,
[data-theme="dark-mode"] h3,
[data-theme="dark-mode"] h4,
[data-theme="dark-mode"] h5,
[data-theme="dark-mode"] h6 {
  color: #1a1a1a; }

[data-theme="dark-mode"] ::selection {
  color: #f8f8f8;
  background-color: #1a1a1a; }

[data-theme="dark-mode"] button.dark {
  border: 1px solid #1a1a1a;
  color: #1a1a1a;
  background-color: #f8f8f8; }
  [data-theme="dark-mode"] button.dark:hover {
    border: 1px solid #1a1a1a;
    background-color: #1a1a1a;
    color: #f8f8f8; }

[data-theme="dark-mode"] path {
  fill: #1a1a1a; }

[data-theme="dark-mode"] .image-set h5 {
  color: #f8f8f8; }

[data-theme="dark-mode"] .connect .social__list path {
  fill: #1a1a1a; }

[data-theme="dark-mode"] .menu-full {
  background-color: #f8f8f8; }

[data-theme="dark-mode"] .light-switch-toggle-ui {
  background-color: #1a1a1a;
  border: 2px solid #1a1a1a; }
  [data-theme="dark-mode"] .light-switch-toggle-ui span {
    color: #1a1a1a; }

[data-theme="dark-mode"] .light-switch-toggle-ui--right {
  background-color: #f8f8f8;
  left: 20%; }

[data-theme="dark-mode"] .navigation:hover .navigation__link {
  color: #404040; }

[data-theme="dark-mode"] .navigation__item .navigation__link--checked {
  border-bottom: 1px solid #1a1a1a; }

[data-theme="dark-mode"] .navigation__item:hover .navigation__link {
  color: #1a1a1a; }

[data-theme="dark-mode"] .navigation__icon {
  background-color: #1a1a1a; }
  [data-theme="dark-mode"] .navigation__icon::before, [data-theme="dark-mode"] .navigation__icon::after {
    background-color: #1a1a1a; }

[data-theme="dark-mode"] .menu-full__list:hover .menu__link {
  color: #262626; }

[data-theme="dark-mode"] .menu__list:hover .menu__link {
  color: #CCCCCC; }

[data-theme="dark-mode"] .menu__item:hover .menu__link {
  color: #1a1a1a; }

[data-theme="dark-mode"] .modal {
  background-color: #f8f8f8; }
  [data-theme="dark-mode"] .modal__button {
    background-color: transparent; }
  [data-theme="dark-mode"] .modal__icon::before, [data-theme="dark-mode"] .modal__icon::after {
    background-color: #1a1a1a; }

[data-theme="dark-mode"] .form__input {
  color: #1a1a1a;
  border: 1px solid #1a1a1a; }
  [data-theme="dark-mode"] .form__input--textarea {
    border: 1px solid #1a1a1a; }

[data-theme="dark-mode"] .form__input::-webkit-input-placeholder {
  color: #1a1a1a; }

[data-theme="dark-mode"] .form__input::-moz-placeholder {
  /* Firefox 19+ */
  color: #1a1a1a; }

[data-theme="dark-mode"] .form__input::-ms-input-placeholder {
  /* IE 10+ */
  color: #1a1a1a; }

[data-theme="dark-mode"] .form__input::-moz-placeholder {
  /* Firefox 18- */
  color: #1a1a1a; }

[data-theme="dark-mode"] .form__input:focus {
  border-bottom: 1px solid #4a4aff; }
  [data-theme="dark-mode"] .form__input:focus--textarea {
    border: 1px solid #1a1a1a; }

[data-theme="dark-mode"] .form__input:focus:invalid {
  border-bottom: 1px solid orangered; }
  [data-theme="dark-mode"] .form__input:focus:invalid--textarea {
    border: 1px solid #1a1a1a; }

[data-theme="dark-mode"] .form__label {
  color: #1a1a1a; }

[data-theme="dark-mode"] .prevNext span:hover {
  border-bottom: 1px solid #1a1a1a; }

[data-theme="dark-mode"] .prevNext [class^="arrow"] path {
  fill: #1a1a1a; }

[data-theme="dark-mode"] footer span {
  color: #1a1a1a; }

[data-theme="dark-mode"] .thankyou a {
  border-bottom: 1px solid #1a1a1a; }

.modal {
  z-index: 3;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  background-color: #1a1a1a;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  cursor: pointer; }
  .modal .image-container {
    transform: translateX(0px);
    max-width: 50rem;
    cursor: default;
    transition: all .3s; }
    .modal .image-container img {
      height: 100%;
      width: 100%;
      border-radius: 0.3rem; }
  .modal .prev {
    margin-right: 4rem; }
    .modal .prev.shake {
      animation: shakePrev .1s; }
  .modal .next {
    margin-left: 4rem; }
    .modal .next.shake {
      animation: shakeNext .1s; }
  .modal__checkbox {
    display: none; }
  .modal__button {
    z-index: 10;
    position: absolute;
    top: 0%;
    right: -17%;
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer; }
    @media (max-width: 56.25em) {
      .modal__button {
        display: block; } }
    .modal__button:hover .modal__icon::before {
      transform: rotate(225deg) scale(1.2); }
    .modal__button:hover .modal__icon::after {
      transform: rotate(-45deg) scale(1.2); }
  .modal__icon {
    display: block;
    width: 100%;
    height: 100%;
    background-color: transparent;
    transition: all 0.3s ease-in-out; }
    @media (max-width: 56.25em) {
      .modal__icon {
        display: block; } }
    @media (max-width: 37.5em) {
      .modal__icon {
        width: 2.2rem; } }
    .modal__icon::before {
      content: '';
      position: absolute;
      top: 48%;
      left: 19%;
      transform-origin: center;
      transform: rotate(225deg);
      display: block;
      width: 90%;
      height: 0.2rem;
      background-color: #f8f8f8;
      transition: all 0.3s ease-in-out; }
      @media (max-width: 37.5em) {
        .modal__icon::before {
          width: 2.2rem; } }
    .modal__icon::after {
      content: '';
      position: absolute;
      top: 48%;
      left: 19%;
      transform-origin: center;
      transform: rotate(-45deg);
      display: block;
      height: 0.2rem;
      width: 90%;
      background-color: #f8f8f8;
      transition: all 0.3s ease-in-out; }
      @media (max-width: 37.5em) {
        .modal__icon::after {
          width: 2.2rem; } }
  .modal__checkbox:checked ~ .modal__button > .modal__icon::before {
    transform: rotate(0deg); }
  .modal__checkbox:checked ~ .modal__button > .modal__icon::after {
    transform: rotate(0deg); }
  .modal.open {
    opacity: 1;
    visibility: visible; }

.featured {
  display: grid;
  grid-column: col-start 3 / col-end 10;
  margin-top: 8rem; }
  @media (max-width: 56.25em) {
    .featured {
      grid-column: col-start 1 / col-end 3; } }
  @media (max-width: 37.5em) {
    .featured {
      margin-top: 4rem; } }

.contact {
  display: grid;
  grid-template-columns: [page-start] 1fr [content] repeat(12, [col-start] 1fr [col-end]) [content-end] 1fr [page-end];
  grid-column: page-start/ page-end; }
  .contact .info {
    display: flex;
    flex-direction: column;
    grid-column: col-start 2 / col-end 5;
    grid-gap: 0rem 2rem;
    margin: 12rem 0 3rem 0; }
    .contact .info .social__item a {
      border-bottom: 1px solid transparent;
      transition: border-bottom .2s ease; }
      .contact .info .social__item a:hover {
        border-bottom: 1px solid #f8f8f8; }
    @media (max-width: 75em) {
      .contact .info {
        grid-column: col-start 3 / col-end 10;
        margin: 12rem 0 2rem 0; } }
    @media (max-width: 56.25em) {
      .contact .info {
        display: flex;
        flex-direction: column;
        grid-column: col-start 1 / col-end 12;
        margin: 8rem 0 3rem 0; } }
    .contact .info .heading {
      max-width: 46rem;
      margin-bottom: 1.6rem; }
      .contact .info .heading > *:not(:last-child) {
        margin-bottom: 1.6rem; }
    .contact .info p {
      font-size: 1.4rem;
      margin-bottom: 2rem; }
      @media (max-width: 75em) {
        .contact .info p {
          margin-bottom: 2.6rem; } }
  @media (max-width: 75em) {
    .contact .connect {
      grid-column: col-start 3 / col-end 10; } }
  @media (max-width: 56.25em) {
    .contact .connect {
      grid-column: col-start 3 / col-end 10; } }
  @media (max-width: 37.5em) {
    .contact .connect {
      grid-column: col-start 1 / col-end 12;
      flex-direction: column-reverse;
      align-content: space-between;
      margin: 0rem 0 2.4rem 0; } }
  .contact .connect .heading {
    margin-bottom: 1.6rem;
    font-size: 3.2rem; }
    @media (max-width: 56.25em) {
      .contact .connect .heading {
        margin-top: 2.6rem; } }
  .contact .connect__content {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse; }
  .contact .connect img {
    position: relative;
    z-index: 1;
    width: 100%;
    border-radius: .2rem; }
  .contact .connect svg {
    margin-top: 3.2rem;
    animation: float 2s ease infinite;
    transition: height .3s;
    cursor: pointer; }
    @media (max-width: 37.5em) {
      .contact .connect svg {
        display: none; } }
    .contact .connect svg:hover {
      animation: float .5s ease infinite; }
    .contact .connect svg path {
      fill: #f8f8f8;
      transform-origin: center;
      transform: rotate(180deg); }
  .contact .form {
    grid-column: col-start 7 / col-end 10;
    margin: 12rem 0 3rem 0; }
    @media (max-width: 75em) {
      .contact .form {
        grid-column: col-start 3 / col-end 10;
        margin: 1rem 0 8rem 0; } }
    @media (max-width: 56.25em) {
      .contact .form {
        grid-column: col-start 1 / col-end 12; } }
    @media (max-width: 37.5em) {
      .contact .form {
        margin: 0rem 0 8rem 0; } }

.img-container {
  position: relative;
  grid-column: col-start 3/ col-end 7;
  margin-top: 10rem; }
  @media (max-width: 75em) {
    .img-container {
      margin-right: 4rem; } }
  @media (max-width: 37.5em) {
    .img-container {
      display: none; } }
  .img-container .image-set img {
    opacity: 0;
    position: absolute;
    max-width: 40rem;
    transition: opacity .5s ease; }
    @media (max-width: 56.25em) {
      .img-container .image-set img {
        display: none; } }

.thankyou {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
  grid-column: col-start 2 / col-end 11;
  grid-gap: 6rem 3rem;
  min-height: 10vw;
  margin: 6rem 0 6rem 0;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 40%;
  white-space: nowrap; }
  .thankyou h1 {
    font-size: 12rem; }
    @media (max-width: 56.25em) {
      .thankyou h1 {
        font-size: 4rem; } }
  .thankyou h1, .thankyou p {
    text-align: center; }
  .thankyou a {
    border-bottom: 1px solid #f8f8f8; }
