.circle {
  z-index: 10;
  width: 0rem;
  height: 0rem;
  display: block;
  border-radius: 100%;
  background-color: white;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
  mix-blend-mode: difference;
  pointer-events: none;
}