.header {
    position: relative;
    display: flex;
    grid-column: col-start 1/col-end 12;
    align-items: baseline;
    justify-content: space-between;
    max-height: 8rem;
    margin-top: 3.2rem;
    white-space: nowrap;

    // animation: fadeDown 1.3s ease;

    @include respond(tab-port) {
        // grid-column: col-start 2 /col-end 11;
        // align-items: center;
    }

    @include respond(phone) {
        justify-content: space-between;
        align-items: center;
        max-height: 6rem;
        margin-top: 3rem;
        // grid-column: col-start 1 /col-end 12;
        // flex-direction: column;

    }
}