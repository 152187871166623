.light-switch {
    position: fixed;
    z-index: 3;
    bottom: 3%;
    right: 3%;
    // display: flex;
    // justify-content: space-between;
    transform: translate(-50%, -50%);
    width: 2.5rem;
    height: 2.5rem;
    transition: all 0.5s;
    // border: 1px dotted orangered;

    @include respond(tab-port) {
        display: none;
    }
}

.light-switch-toggle {
    position: absolute;
    z-index: 1;
    top: 30%;
    left: 100%;
    transform: translate(-50%, -50%);
    -webkit-appearance: none;
    opacity: 0;
    visibility: hidden;
    width: 4.5rem;
    height: 4.5rem;
    border: 2px solid $white;
    border-radius: 50%;
    background-color: $black;
    transition: all 0.5s;

    @include respond(tab-port) {
    }
}

.light-switch-toggle-ui {
    position: absolute;
    top: 50%;
    left: 25%;
    transform-origin: center;
    transform: translate(-50%, -50%);
    display: block;
    width: 4.5rem;
    height: 2rem;
    border: 2px solid $white;
    border-radius: 2rem;
    // border-top-left-radius: 53rem;
    // border-bottom-left-radius: 53rem;
    background-color: $white;

    -webkit-box-shadow: 6px 8px 16px -7px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 6px 8px 16px -7px rgba(0, 0, 0, 0.6);
    box-shadow: 6px 8px 16px -7px rgba(0, 0, 0, 0.6);
    transition: all 0.5s;
    cursor: pointer;

    span {
        white-space: nowrap;
        position: absolute;
        transition: all 0.2s;
        font-size: xx-small;
        top: 0;
        left: 0;
        transform: translateX(-10%);
        opacity: 0;
        color: $white;
    }

    &:hover span {
        opacity: 1;
        top: -25px;
    }
}

.light-switch-toggle-ui--right {
    position: absolute;
    top: 50%;
    left: 80%;
    transform-origin: center;
    transform: translate(-50%, -50%);
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    // border: 2px solid $black;
    border-radius: 50%;
    // border-top-right-radius: 53rem;
    // border-bottom-right-radius: 53rem;
    background-color: $black;
    transition: all 0.1s ease-out;
    cursor: pointer;

    &:hover {
    }
}

.light-switch-toggle-ui.active {
    background-color: $black;
    border: 2px solid $white;

    @include respond(tab-port) {
        // top:50%;
        // left:70%;
    }
}
