.modal {
    z-index: 3;
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    background-color: $black;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
    cursor: pointer;
   

    & .image-container {
        transform: translateX(0px);
        max-width: 50rem;
        cursor: default;
      
        transition: all .3s;

        img {
            height: 100%;
            width: 100%;
            border-radius: 0.3rem;
         
        }
    }

  .prev{
      margin-right: 4rem;

      &.shake{
          animation: shakePrev .1s;
      }
  }

  .next{
    margin-left: 4rem;
    &.shake{
        animation: shakeNext .1s;
    }
}

    &__checkbox {
        display: none;
    }

    &__button {
        // display: none;
        z-index: 10;
        position: absolute;
        top: 0%;
        right: -17%;
        // transform: translateX(-50%);
        height: 3.5rem;
        width: 3.5rem;
        border-radius: 50%;
        background-color:transparent;
        cursor: pointer;


        @include respond(tab-port) {
            display: block;
        }

        &:hover .modal__icon::before {
            transform: rotate(225deg) scale(1.2);
        }

        &:hover .modal__icon::after {
            transform: rotate(-45deg) scale(1.2);
        }
    }

    &__icon {
        // position: absolute;
        // top: 10%;
        // right: 5%;
        // display: none;
        display: block;
        width: 100%;
        height: 100%;
        background-color: transparent;
        // transform: translate(-50%,-50%);
        transition: all 0.3s ease-in-out;

        @include respond(tab-port) {
            display: block;
        }

        @include respond(phone) {
            width: 2.2rem;
        }

        &::before {
            content: '';
            position: absolute;
            top: 48%;
            left:19%;
            transform-origin: center;
            transform: rotate(225deg);
            display: block;
            width: 90%;
            height: 0.2rem;
            background-color: $white;
            transition: all 0.3s ease-in-out;

            @include respond(phone) {
                width: 2.2rem;
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: 48%;
            left:19%;
            transform-origin: center;
            transform: rotate(-45deg);
            display: block;
            height: 0.2rem;
            width: 90%;
            background-color: $white;
            transition: all 0.3s ease-in-out;

            @include respond(phone) {
                width: 2.2rem;
            }
        }
    }

    &__checkbox:checked ~ &__button > &__icon::before {
        transform: rotate(0deg);
    }

    // &__checkbox:checked  .model{
    //     display: none;
    //     visibility: hidden;
    // }

    &__checkbox:checked ~ &__button > &__icon::after {
        transform: rotate(0deg);
    }

    &__checkbox:checked ~ &__button > &__icon {
        // background-color: transparent;
    }

    // &__checkbox:checked ~ .menu-full{
    //     opacity: 1;
    //     visibility: visible;
    // }
    &.open{
        opacity: 1;
        visibility: visible;

        // & .image-container .clicked {
        //     // transform: translateX(100px);
        //     animation: fadeOutAndIn 1.5s;
        // }


    }


}

