footer{
    display: flex;
    grid-column: col-start 2/ col-end 11;
    justify-content: flex-start;
    align-items: baseline;
    margin-bottom: 8rem;
    border: 1px solid red;
    // padding-top: 3.2rem;
    // border-top: 1px solid $black;

    @include respond(tab-land) {
        grid-column: col-start 1 / col-end 12;
    }


    @include respond(phone) {
        grid-column: col-start 1 / col-end 12;
        justify-content: flex-end;
    }
    

    // .buttons{
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: space-around;
    //     min-height: 11rem;
    //     margin-right: 2rem;

    //     @include respond(tab-land) {
    //         margin-right: 0;
         
    //     }
    
    // }

    svg{
      
        height:30%;
        // animation: float 2s ease infinite;
        // transition: height .3s;
        &:hover{
            // animation: float .5s ease infinite;

        }
        cursor: pointer;
        path{
            fill: $white;
          
        }
        
    }
    span{
        font-size: 1.6rem;
        cursor: pointer;
    }
}