html {
    box-sizing: border-box;
    font-size: 62.5%;

}
body {
    background-color: $black;
}


html.open {
    overflow-y: hidden;
    -webkit-overflow-scrolling: none;
}

*,
*::after,
*::before {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}

main {
    display: block;
}

p,
a,
h1,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $white;
}


::selection{
    color: $black;
    background-color: $white;
}

a {

    background-color: transparent;
    text-decoration: none;

    &:focus{
        // border: 1px solid blue;
    }

}

ul {
    list-style: none;
}

button * {
    pointer-events: none;
}

img,
embed,
iframe,
object,
video {
    display: block;
    max-width: 100%;
    border-style: none;
}

input,
textarea,
select,
button {
    color: inherit;
    font: inherit;
    letter-spacing: inherit;
}


body.open {
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}