//MEDIA QUERIES
@mixin respond($breakpoint) {

	@if $breakpoint==phone {
		@media (max-width: 37.5em) {
			@content
		}

		; //600px
	}

	@if $breakpoint==tab-port {
		@media (max-width: 56.25em) {
			@content
		}

		; //900px
	}

	@if $breakpoint==tab-land {
		@media (max-width: 75em) {
			@content
		}

		; //1200px
	}

	@if $breakpoint==desktop {
		@media (min-width: 112.5em) {
			@content
		}

		; //1800px 
	}

}


//UTILITY MIXINS
@mixin fullImage {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 0 0;
}

@mixin flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin auto {
	margin: 0 auto;

}

@mixin center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

//STYLE
@mixin boxShadow {
	box-shadow: 0 1.2rem 2rem rgba(rgb(20, 20, 20), .05);
}

@mixin gradientText {
	background-image: linear-gradient(to right, $color-primary-light, $color-primary-dark);
	background-clip: text;
	-webkit-background-clip: text;
	color: transparent;
}