@import url("https://fonts.googleapis.com/css?family=Work+Sans:400");

@font-face {
    src: url(../fonts/Agrandir/Agrandir-regular.otf);
    font-family: "Agrandir";
    font-weight: 400;
}

body {
    font-family: "Agrandir";
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // transition: background-color .5s;
}

p,
a,
h1,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Agrandir";
    font-weight: 400;
    transition: color 0.2s;
}

a button {
    font-size: 1.4rem;
}

//HEADINGS
%heading {
    font-family: "Agrandir";
    font-weight: 400;
}

h1 {
    @extend %heading;
    font-size: 7.2rem;
    line-height: 1.2;

    @include respond(tab-land) {
        font-size: calc(7.2rem - 8px);
    }

    @include respond(tab-port) {
        font-size: calc(7.2rem - 16px);
    }
}

h2 {
    @extend %heading;
    font-size: 5.6rem;
    line-height: 1.2;

    @include respond(tab-land) {
        font-size: calc(5.6rem - 8px);
    }

    @include respond(tab-port) {
        font-size: calc(5.6rem - 16px);
    }
}

h3 {
    @extend %heading;
    font-size: 4.3rem;
    line-height: 1;

    @include respond(tab-land) {
        font-size: calc(4.3rem - 8px);
    }

    @include respond(tab-port) {
        font-size: calc(4.3rem - 16px);
    }
}

h4 {
    @extend %heading;
    font-size: 3.3rem;
    line-height: 1.3;

    @include respond(tab-land) {
        font-size: calc(3.3rem - 8px);
    }

    @include respond(tab-port) {
        font-size: calc(3.3rem - 12px);
    }
}

h5 {
    @extend %heading;
    font-size: 2.4rem;
    line-height: 1.4;

    @include respond(tab-land) {
        font-size: calc(2.4rem - 6px);
    }
}

h6 {
    @extend %heading;
    font-size: 1.6rem;
    line-height: 1.4;

    @include respond(tab-land) {
        font-size: 1.5rem;
    }

    @include respond(tab-port) {
        font-size: 1.4rem;
    }
}
