button {
	display: inline-block;
	border: none;
	padding: 1rem 2rem 0.7rem 2rem;
	color: $black;
	background-color: $white;
	border: 1px solid $black;
	letter-spacing: 0.091em;
	line-height: 1em;
	text-align: center;
	white-space: nowrap;
	outline: none;
	transition: all 0.3s ease;
	cursor: pointer;

	&:hover {
		border: 1px solid $black;
		color: $white;
		background-color: $black;
	}
}
