@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(1vw);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}


@keyframes fadeDown {
    0% {
        opacity: 0;
        transform: translateY(-2vw);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes float {
    0% {
        transform: translateY(10%);
    }

    50% {
        transform: translateY(-10%);
    }

    100% {
        transform: translateY(10%);
    }
}

@keyframes stretchIt {
    0% {
        width: 1.5rem;
        height: 1.5rem;
    }

    75% {
        width: 3.5rem;
        height: 3.5rem;
    }

    100% {
        width: 2.5rem;
        height: 2.5rem;
    }
}

@keyframes shakePrev{
    0% {
  
        transform: translateX(0);
    }

    50% {
  
        transform: translateX(-10px);
    }


    100% {
     
        transform: translateX(0);
    }
}


@keyframes shakeNext{
    0% {
  
        transform: translateX(0);
    }

    50% {
  
        transform: translateX(10px);
    }


    100% {
     
        transform: translateX(0);
    }
}

@keyframes fadeOutAndIn{
    0% {
  
        opacity: 0;
    }



    100% {
     
     
        opacity: 1;
    }
}

// @keyframes collapseBefore{

//     0%{
//         top:0;
//     }

//     50%{
//           transform: rotate(225deg); 
//     }
// }



// @keyframes collapseAfter{

//     0%{
//         top:0;
//     }

//     50%{
//           transform: rotate(-45deg); 
//     }
// }
