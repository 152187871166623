.image-set {
    position: relative;
    width: 100%;
    margin: 0 auto;
    transition: all .3s;
    animation: fadeIn 0s;

    &:hover{

        transform: translateY(-10px) scale(1.01);
        // box-shadow: rgba(0, 0, 0, 0.28);
        @include boxShadow;

        @include respond(phone){
         
            transform: translateY(0px) scale(1);
        }

    }


    .overlay{
        opacity: 0;
        transition: all .3s ease;
        position: absolute;
        z-index: 2;
        background-color: rgba(0, 0, 0, 0.28);
        width: 100%;
        height: 100%;        
        border-radius: .5rem;
    }
    

    h5 {
        font-size: 1.6rem;
        position: absolute;
        z-index: 3;
        top: 3rem;
        left: 2rem;
        opacity: 0;
        color: $white;
        transition: opacity 0.5s, top 0.5s;
    }

    img {
        position: relative;
        z-index: 1;
        width: 100%;
        border-radius: .5rem;
      

    }
}

a:hover > .image-set .overlay,
a:focus > .image-set .overlay{  opacity: 1;}

a:focus  > .image-set {

    transform: translateY(-10px) scale(1.01);
    // box-shadow: rgba(0, 0, 0, 0.28);
    @include boxShadow;

    @include respond(phone){
         
        transform: translateY(0px) scale(1);
    }

}


a:hover > .image-set h5,
a:focus > .image-set h5 {
        top: 2rem;
        opacity: 1;
}