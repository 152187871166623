.menu {
    grid-column: col-start 8 / col-end 10;
    margin-top: 10rem;


    @include respond(phone) {
        margin-top: 2rem;
        grid-column: col-start 1 / col-end 1;
    }

    &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-height: 70vh;
        white-space: nowrap;
        transition: color 1.2s;

        &:hover .menu__link {
            color: $gray-3;
        }
    }

    &__item {
        align-items: flex-start;
        transition: color .2s;

        &:hover~.image-list>.image-set {
            opacity: 1;
        }

        &:hover .menu__link {
            color: $white;
            // margin-left: .5rem;
        }
    }

    &__link {
        display: block;
        opacity: 0;
        transform: translateY(10vh);
        font-size: 2.4rem;
        transition: all .5s;


        @include respond(phone) {
            font-size: 2.8rem;
        }

        &:hover {
            color: $black;
        }

    }

    &-full {
        z-index: 2;
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
        background-color: $black;
        opacity: 0;
        visibility: hidden;
        transition: all .5s;

        &__list {
            @include center;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            top: 40%;
            white-space: nowrap;


            &:hover .menu__link {
                color: $gray-3;
            }

            & .menu__item {
                align-items: flex-start;
                transition: color .2s;


                & .menu__link {
                    font-size: 6rem;

                    @include respond(phone) {
                        font-size: 4rem;
                    }
                }

                &:hover .menu__link {
                    color: $white;
                    // margin-left: .5rem;

                }
            }
        }
    }
}
