.form {
  min-width: 35rem;

  @include respond(tab-port) {
    min-width: 100%;
  }

  & .heading {
    margin-bottom: 2.4rem;
  }

  // &__group:not(:last-child){
  //     margin-bottom: 0rem;
  // }

  &__input {
    display: block;
    width: 100%;
    padding: 1.2rem 2.2rem 0.5rem 1.2rem;
    border: none;
    border: 1px solid $white;
    background-color: transparent;
    font-family: inherit;
    font-size: 1.2rem;
    color: $white;

    @include respond(tab-port) {
      width: 100%;
    }

    &--textarea {
      resize: none;
      width: 100%;
      min-height: 15rem;
      border: 1px solid $white;

      //    &:placeholder-shown + &__label{
      //     transform: translate(-7.4rem, -14.2rem);
      // }

      & ~ .form__label {
        transform: translate(-7.4rem, -14.2rem);
      }
    }

    &--submit {
      display: none;
      // width: 18%;
      // color: orangered;
    }
  }

  &__input::-webkit-input-placeholder {
    color: white;
  }

  &__input::-moz-placeholder {
    /* Firefox 19+ */
    color: white;
  }

  &__input::-ms-input-placeholder {
    /* IE 10+ */
    color: white;
  }

  &__input::-moz-placeholder {
    /* Firefox 18- */
    color: white;
  }

  &__input:focus {
    outline: none;
    border-bottom: 1px solid rgb(222, 255, 74);
  }

  &__input:focus:invalid {
    border-bottom: 1px solid orangered;
  }

  &__label {
    backface-visibility: hidden;
    display: block;
    transform: translate(-5.5rem, -3rem);
    margin-top: 0.5rem;
    margin-left: 1.2rem;
    opacity: 0;
    color: $white;
    font-size: 1.2rem;
    transition: all 0.3s ease;
  }

  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    // transform: translateX(-3rem);
    // transform: translateY(-3rem);
  }
}
