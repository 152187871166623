[data-theme="dark-mode"] {
    & body {
        background-color: $white;
    }

    & p,
    a,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $black;
    }
    ::selection {
        color: $white;
        background-color: $black;
    }

    & button.dark {
        border: 1px solid $black;
        color: $black;
        background-color: $white;

        &:hover {
            border: 1px solid $black;
            background-color: $black;
            color: $white;
        }
    }

    path {
        fill: $black;
    }

    .image-set {
        h5 {
            color: $white;
        }
    }

    .connect .social__list path {
        fill: $black;
    }

    & .menu-full {
        background-color: $white;
    }

    .light-switch-toggle-ui {
        background-color: $black;
        border: 2px solid $black;
        span {
            color: $black;
        }
    }

    .light-switch-toggle-ui--right {
        // width: 60%;
        background-color: $white;
        // border: 2px solid  $white;
        left: 20%;
    }

    & .navigation:hover .navigation__link {
        color: $gray-2;
    }

    & .navigation__item {
        & .navigation__link--checked {
            border-bottom: 1px solid $black;
        }

        &:hover {
            .navigation__link {
                color: $black;
            }
        }
    }

    & .navigation__icon {
        background-color: $black;

        &::before,
        &::after {
            background-color: $black;
        }
    }

    .menu-full__list:hover .menu__link {
        color: $gray-1;
    }

    & .menu__list:hover .menu__link {
        color: $gray-7;
    }

    & .menu__item {
        &:hover {
            .menu__link {
                color: $black;
            }
        }
    }

    .modal {
        background-color: $white;
        &__button {
            background-color: transparent;
        }

        &__icon::before,
        &__icon::after {
            background-color: $black;
        }
    }

    .form {
        &__input {
            color: $black;
            border: 1px solid $black;

            &--textarea {
                border: 1px solid $black;
            }
        }

        &__input::-webkit-input-placeholder {
            color: $black;
        }

        &__input::-moz-placeholder {
            /* Firefox 19+ */
            color: $black;
        }

        &__input::-ms-input-placeholder {
            /* IE 10+ */
            color: $black;
        }

        &__input::-moz-placeholder {
            /* Firefox 18- */
            color: $black;
        }

        &__input:focus {
            border-bottom: 1px solid rgb(74, 74, 255);

            &--textarea {
                border: 1px solid $black;
            }
        }

        &__input:focus:invalid {
            border-bottom: 1px solid orangered;

            &--textarea {
                border: 1px solid $black;
            }
        }

        &__label {
            color: $black;
        }

        &__input:placeholder-shown + &__label {
        }
    }

    .prevNext {
        span {
            &:hover {
                border-bottom: 1px solid $black;
            }
        }

        [class^="arrow"] {
            path {
                fill: $black;
            }
        }
    }

    footer {
        // border-top: 1px solid $white;
    }

    footer span {
        color: $black;
    }

    .thankyou a {
        border-bottom: 1px solid $black;
    }
}
