// body{
//     background-color: $white;
// }

.featured{
    display: grid;
    grid-column: col-start 3 / col-end 10;
    margin-top: 8rem;
    // justify-content: flex-end;

    @include respond(tab-port) {

        grid-column: col-start 1 / col-end 3;
    }
    @include respond(phone) {
        margin-top: 4rem;
    }
}