.navigation {
    display: flex;
    min-width: 24rem;
    justify-content: flex-end;
    align-items: baseline;

    @include respond(phone) {
        min-width: 0;
    }

    &__checkbox {
        display: none;

    }

    &:hover .navigation__link {
        color: $gray-2;
    }

    &__button {
        display: none;
        z-index: 10;
        position: relative;
        height: 4rem;
        width: 4rem;
        cursor: pointer;

        @include respond(tab-port) {
            display: block;
        }

        &:hover .navigation__icon::before {
            top: -.9rem;
        }

        &:hover .navigation__icon::after {
            top: .9rem;
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        display: none;
        width: 3rem;
        height: .2rem;
        background-color: $white;
        transform: translate(-50%, -50%);
        transition: all .3s ease-in-out;

        @include respond(tab-port) {
            display: block;
        }

        @include respond(phone) {
            width: 2.2rem;
        }

        &::before {
            content: "";
            position: absolute;
            top: -.7rem;
            display: block;
            width: 3rem;
            height: .2rem;
            background-color: $white;
            transition: all .3s ease-in-out;

            // &:hover{
            //     top: -.8rem;
            // }

            @include respond(phone) {
                width: 2.2rem;
            }

        }

        &::after {
            content: "";
            position: absolute;
            top: .7rem;
            display: block;
            height: .2rem;
            width: 3rem;
            background-color: $white;
            transition: all .3s ease-in-out;

            // &:hover{
            //     top: .8rem;
            // }


            @include respond(phone) {
                width: 2.2rem;
            }
        }

    }

    &__checkbox:checked~&__button>&__icon::before {
        top: 0rem;
        transform-origin: center;
        transform: rotate(225deg);


    }

    &__checkbox:checked~&__button>&__icon::after {
        top: 0rem;
        transform-origin: center;
        transform: rotate(-45deg);


    }

    &__checkbox:checked~&__button>&__icon {
        background-color: transparent;
    }

    &__checkbox:checked~.menu-full {
        opacity: 1;
        visibility: visible;
    }

    &__list {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @include respond(tab-port) {
            display: none;
        }

        .navigation__item {
            align-items: flex-start;
        }

        .navigation__link {
            font-size: 1.4rem;
            padding-bottom: .3rem;
            border-bottom: 1px solid transparent;

            &:hover {
                color: $white;
            }

            &--checked {
                border-bottom: 1px solid $black;
            }

            @include respond(tab-port) {
                font-size: 90%;
            }
        }
    }
}