.img-container {
    position: relative;
    grid-column: col-start 3/ col-end 7;
    margin-top: 10rem;

    @include respond(tab-land) {
        margin-right: 4rem;
      
    }

    @include respond(phone) {
        display: none;
        // margin-top: 0rem;
      
    }



    & .image-set img {
        opacity: 0;
        position: absolute;
        max-width: 40rem;
        transition: opacity .5s ease;

        @include respond(tab-port) {
            display: none;

        }

    }
}